<template>
    <div class="my-tag" :class="isDefault ? 'is-default' :'no-default'">
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        isDefault:{
            type:Boolean,
            default:true,
        },
        text:{
            type:String,
            default:'',
        }
    },
    data () {
        return {

        }
    },
    computed: {

    },
    methods: {

    },
    mounted: function() {
        
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .my-tag{
        display: inline-block;
        padding: 4px 6px;
        font-size: 12px;
        line-height: 1em;

        &.is-default{
            background-color: #edeef4;
            
        }

        &.no-default{
            color: #3b68ff;
            background-color: #dae2ff;
        }

        & + .my-tag{
            margin-left: 6px;
        }
    }

</style>
