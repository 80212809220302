<template>
    <span class="my__checkbox">
        <span v-if="!readonly">
            <el-checkbox-group
                v-model="model"
                style="display:inline-block;"
                :size="size"
            >
                <component
                    v-for="item in list"
                    :key="item[props.value]"
                    :label="item[props.value]"
                    :is="button ? 'el-checkbox-button' : 'el-checkbox'"
                >
                    {{item[props.label]}}
                </component>
            </el-checkbox-group>

            <el-checkbox
                v-model="otherController"
                style="margin-left:1em;"
                v-if="other"
                disabled
            >
                <el-input
                    v-model="input"
                    placeholder="其它"
                    size="mini"
                ></el-input>
            </el-checkbox>
        </span>
        <span v-else>
            {{readonlyStr}}
        </span>
        <slot></slot>
    </span>
</template>

<script>
export default {
    name: 'my-checkbox',
    props: {
        value: {
            type: [Array, String],
            default() {
                return [];
            },
        },
        action: {
            type: String,
            default: '',
        },
        other: {
            type: Boolean,
            default: false,
        },
        data: {
            type: Array,
            default() {
                return [];
            },
        },
        props: {
            type: Object,
            default() {
                return {
                    value: 'key',
                    label: 'value',
                };
            },
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        all: {
            type: Boolean,
            default: false,
        },
        modelStr: {
            type: Boolean,
            default: false,
        },
        strSpliter: {
            tyep: String,
            default: ',',
        },
        button: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            default: 'small',
        },
    },
    data: function () {
        return {
            list: [],
            otherController: false,

            readonlyStr: '',

            selected: [],
        };
    },
    computed: {
        model: {
            get: function () {
                if (this.modelStr) {
                    var valueArr = [];
                    if (getType(this.value) === 'string') {
                        valueArr = this.value.split(this.strSpliter);
                    }
                    if (valueArr[0] === '') {
                        return valueArr.slice(1);
                    } else {
                        return valueArr;
                    }
                } else {
                    return this.other ? this.value.slice(0, -1) : this.value;
                }
            },
            set: function (val) {
                if (this.modelStr) {
                    this.$emit('input', val.join(this.strSpliter));

                    this.$nextTick(() => {
                        this.$emit('change', val.join(this.strSpliter));
                    });
                } else {
                    if (this.other) {
                        var res = clone(val);
                        res.push(this.input);
                    }
                    this.$emit('input', this.other ? res : val);

                    this.$nextTick(() => {
                        this.$emit('change', val.join(this.strSpliter));
                    });
                }
            },
        },
        input: {
            get: function () {
                return this.other ? this.value.slice(-1) : '';
            },
            set: function (n) {
                this.otherController = !!n;

                if (this.other) {
                    var res = clone(this.model);
                    res.push(n);
                }
                this.$emit('input', this.other ? res : val);

                this.$nextTick(() => {
                    this.$emit('change', val.join(this.strSpliter));
                });
            },
        },
    },
    watch: {
        data: {
            handler: function (n, o) {
                if (n != o) {
                    this.list = n;
                    this.readonlyStr = this.fetchKey();
                }
            },
            deep: true,
        },
        value: function (n, o) {
            this.readonlyStr = this.fetchKey();
        },
        input: function (n, o) {
            if (
                n.slice(-1) != o.slice(-1) &&
                /,|\/|;|、|，/.test(n.slice(-1))
            ) {
                ShowMsg.call(this, '无效的特殊字符', 'error');
                this.input = o;
            }
        },
    },
    methods: {
        queryData: function () {
            if (!!this.action) {
                this.$get(this.action, function (data) {
                    this.list = data;

                    this.readonlyStr = this.fetchKey();
                });
            } else {
                this.list = this.data;

                this.readonlyStr = this.fetchKey();
            }
        },
        fetchKey: function () {
            var that = this,
                res = [],
                selected = [];

            this.list.forEach(function (item) {
                let label = item[that.props.label],
                    value = item[that.props.value];

                if (that.all) {
                    // 全选
                    res.push(label);
                    selected.push(item);

                    return;
                }

                if (that.modelStr) {
                    // 绑定字符串
                    if (that.model.includes(value)) {
                        res.push(label);
                        selected.push(item);
                    }

                    return;
                }

                if (value == that.value) {
                    res.push(label);
                    selected.push(item);
                }
            });

            this.selected = selected;

            return res.join(',');
        },
    },
    mounted: function () {
        this.queryData();
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.my__checkbox {
}

@media screen and (max-width: 500px) {
    .my__checkbox {
        ::v-deep .el-checkbox {
            margin-left: 0;
            margin-right: 1em;
        }
    }
}
</style>
