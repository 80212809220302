// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".my-form-item-group{width:100%}.my-form-item-group .form-item-group_title{font-weight:700;color:#606266;line-height:32px;padding:0 1em;border-bottom:1px solid #e8e8e8;border-right:1px solid #e8e8e8;background-color:#ebf0ff}.my-form-item-group .form-item-group_title:before{content:\"◆\";margin-right:.5em}.my-form-item-group .form-item-group_body{display:flex;width:100%;flex-wrap:wrap;position:relative}.my-form-item-group .form-item-group_body:after{content:\"\";display:block;width:0;height:100%;position:absolute;right:0;top:0;border-right:1px solid #e8e8e8}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryColor": "#3b68ff",
	"primaryColor9": "#4f77ff",
	"primaryColor8": "#6286ff",
	"primaryColor7": "#7695ff",
	"primaryColor6": "#89a4ff",
	"primaryColor5": "#9db4ff",
	"primaryColor4": "#b1c3ff",
	"primaryColor3": "#c4d2ff",
	"primaryColor2": "#d8e1ff",
	"primaryColor1": "#ebf0ff",
	"successColor": "#52c41a",
	"successColor9": "#63ca31",
	"successColor8": "#75d048",
	"warningColor": "#faad14",
	"warningColor9": "#fbb52c",
	"warningColor8": "#fbbd43",
	"dangerColor": "#ef4040",
	"dangerColor9": "#f15353",
	"dangerColor8": "#f26666",
	"infoColor": "#909399",
	"infoColor9": "#9b9ea3",
	"infoColor8": "#a6a9ad",
	"navBgColor": "#304156",
	"navTextColor": "#bfcbd9",
	"navTextActiveColor": "#ffd04b",
	"topNavBarHeight": "80px"
};
module.exports = ___CSS_LOADER_EXPORT___;
